import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Vuex)
Vue.use(VueAxios, axios)

import App from './App.vue'

Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    base_url: process.env.VUE_APP_API_BASE ? process.env.VUE_APP_API_BASE : "",
    token: null,
    secret: null,
    site_currency: "GBP",
  },
  mutations: {
    initialiseStore(state){
        var token = localStorage.getItem("bde_token");
        var secret = localStorage.getItem("bde_secret");
        if(token && secret){
            state.token = token;
            state.secret = secret;
        }
        var terminal_id = localStorage.getItem("bde_pos_terminal_id");
        if(terminal_id){
            state.pos_config = {
                terminal : {
                    unique_id: terminal_id
                }
            }
        }
    },
    setAuth(state, auth){
      state.token = auth.token;
      state.secret = auth.secret;
      localStorage.setItem("bde_token", auth.token);
      localStorage.setItem("bde_secret", auth.secret);
    },
  }
})

axios.interceptors.request.use(function (config) {
  if(store.state.token)
    config.headers.common['Authorization'] = 'token:' + store.state.token + ':' + store.state.secret;
  return config
}, function (error) {
// Do something with request error
   return Promise.reject(error);
});

new Vue({
  render: h => h(App),
  store: store,
}).$mount('#app')
