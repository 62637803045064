<template>
  <div id="app">
    <Tracker :order_id="order_id" :digest="digest" v-if="order_id" />
    <b-alert show v-if="!order_id" variant="danger">
        The link you followed cannot be used to track an order.
    </b-alert>
  </div>
</template>

<script>
import Tracker from './components/Tracker.vue'

export default {
  name: 'App',
  components: {
    Tracker
  },
  data(){
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);

    return {
      order_id: parseInt(params.get("order")),
      digest: params.get("digest"),
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
